<template>
  <div
    :class="[large ? 'size-28 text-5xl' : 'size-10']"
    class="flex items-center justify-center rounded-full bg-gradient font-bold uppercase text-white shadow-custom"
  >
    {{ computedInitial[0]?.charAt(0) }}{{ computedInitial[1]?.charAt(0) }}
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    initials: string
    large?: boolean
  }>(),
  {
    initials: '',
    large: false,
  },
)

const computedInitial = computed(() => {
  return props.initials ? props.initials.split(' ') : ''
})
</script>
